import React, { useContext, useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Network from "./layouts/Network";

/// Dashboard
import Home from "./components/Dashboard/Home";
import TrainingVideos from "./components/Dashboard/dashboard-elements/TrainingVideos";

//Update Pages
import SvgIcons from "./components/Dashboard/SvgIcons";

//Apps

import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import OrderHistoryList from "./components/OrderHistory/OrderHistoryList";
import BankDetails from "./components/BankDetails/BankDetails";
import WaitList from "./components/WaitingList/waitList";
import WaitListDetails from "./components/WaitingList/waitingListDetails";
import Earnings from "./components/Earnings/Earnings";
import ReviewsList from "./components/ReviewsRatings/ReviewsList";
import FollowersList from "./components/Followers/FollowersList";
import OfferList from "./components/Offers/OfferList";
import SupportChat from "./components/SupportChat/SupportChat";

import OrderAlert from "./components/Notification/OrderAlert";
import Notification from "./components/Notification/Notifications";
import BrowserNotification from "./components/Notification/BrowserNotification";

import { ThemeContext } from "../context/ThemeContext";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerProfile } from '../Redux/Slice';

import io from 'socket.io-client';

const socket = io('https://dev-astromind-api.astromindcounsel.com', {
  withCredentials: true,
  transports: ['websocket', 'polling'],
  query: {
    who: 'astrologer'
  }
});

const Markup = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { AstrologerProfile } = useSelector((state) => state.Astromind);

  const token = localStorage.getItem('astrologer-auth-token');

  useEffect(() => {
    dispatch(GetAstrologerProfile(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (AstrologerProfile?.data?.data?.order?.id) {
      console.log("test");
      if (AstrologerProfile?.data?.data?.order?.type == "call") {
        localStorage.setItem("orderId", AstrologerProfile?.data?.data?.order?.id);
        navigate('/work-desk/');
      }
      else if (AstrologerProfile?.data?.data?.order?.type == "chat") {
        localStorage.setItem("chatOrderId", AstrologerProfile?.data?.data?.order?.id);
        localStorage.setItem("roomName", AstrologerProfile?.data?.data?.order?.astrologer_name);
        localStorage.setItem("userId", AstrologerProfile?.data?.data?.order?.userId);
        localStorage.setItem("messageSessionId", AstrologerProfile?.data?.data?.order?.chatSession?.id);
        navigate('/work-desk/');
        // window.location.reload(false);
      }
    }
  }, [AstrologerProfile]);

  const allroutes = [
    // Dashboard
    { url: "", component: <Home /> },
    { url: "/dashboard/", component: <Home profile={AstrologerProfile} /> },
    { url: "/profile/", component: <AppProfile profile={AstrologerProfile} /> },
    { url: "/training-videos/", component: <TrainingVideos /> },

    // Call Logs
    { url: "/call-logs/", component: <OrderHistoryList /> },
    { url: "/chat-logs/", component: <OrderHistoryList /> },

    // Bank Details

    { url: "/bank-details/", component: <BankDetails /> },

    { url: "/work-desk/", component: <WaitListDetails socket={socket} /> },

    // { url: "/wait-list/details/:id", component: <WaitListDetails /> },

    // Earnings
    { url: "/earnings/", component: <Earnings /> },

    // Reviews
    { url: "/ratings-reviews/", component: <ReviewsList /> },

    // Followers
    { url: "/followers/", component: <FollowersList /> },

    // Offers
    { url: "/offers/", component: <OfferList /> },

    // Customer Support
    { url: "/customer-support/", component: <SupportChat socket={socket} /> },

    // Offers
    { url: "/notifications/", component: <Notification /> },
  ];


  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]

    if (url.indexOf(path) <= 0) {
      return <Error404 />
    }
  }

  return (
    <>
      <Routes>
        <Route path='/page-lock-screen' element={<LockScreen />} />
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/page-error-403' element={<Error403 />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />


        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (

            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>

      <ScrollToTop />

    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const { AstrologerProfile } = useSelector((state) => state.Astromind)

  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav profile={AstrologerProfile} />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
        <div >
          <BrowserNotification socket={socket} />
          <OrderAlert />
          <Network />
        </div>
      </div>
      <Footer />
    </div>
  )
};



export default Markup;
